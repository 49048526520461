<template>
  <div class="layout">
    <header>
      <div class="header-row">
        <div class="left">
          <div class="logo">
            <img src="@/assets/images/logo.png">
            <span>赚钱呗</span>
          </div>
          <nav class="nav">
            <div :class="{'active': $route.path.includes('/home')}" @click="$router.push('/home')">首页</div>
            <div :class="{'active': $route.path.includes('/publishManage')}" @click="$router.push('/publishManage')">发布管理</div>
            <div :class="{'active': $route.path.includes('/message')}" @click="$router.push('/message')">
              <div class="nav-message">
                <span>消息</span>
                <div v-if="unread" class="unread">{{unread > 99 ? '99+':unread}}</div>
              </div>
            </div>
          </nav>
        </div>

        <el-dropdown>
          <div class="btn-user">
            <img class="avatar" :src="userInfo.headImg || require('@/assets/images/avatar-default.png')">
            <span>{{userInfo.nickName || ''}}</span>
            <img class="arrow-down" src="@/assets/images/arrow-down.png">
          </div>
          <el-dropdown-menu slot="dropdown">
            <template v-if="isRealName">
              <el-dropdown-item >
                <div class="user-dropdown" @click="$router.push('/user/userAccount')">
                  <img src="@/assets/images/user-account.png">
                  <span>账户信息</span>
                </div>
              </el-dropdown-item>
              <el-dropdown-item >
                <div class="user-dropdown" @click="$router.push('/user/userAsset')">
                  <img src="@/assets/images/user-assets.png">
                  <span>账户资产</span>
                </div>
              </el-dropdown-item>
              <el-dropdown-item >
                <div class="user-dropdown" @click="$router.push('/user/userMember')">
                  <img src="@/assets/images/user-member.png">
                  <span>会员中心</span>
                </div>
              </el-dropdown-item>
              <el-dropdown-item >
                <div class="user-dropdown" @click="$router.push('/user/userAccountSafty')">
                  <img src="@/assets/images/user-safe.png">
                  <span>账户与安全</span>
                </div>
              </el-dropdown-item>
            </template>
            <el-dropdown-item divided>
              <div class="user-dropdown" @click="logout">
                <img src="@/assets/images/user-logout.png">
                <span>退出</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

      </div>
    </header>
    <main>
      <div class="content">
        <router-view/>
      </div>
    </main>
    <footer>
      <div class="footer-row">
        <div class="left">
          <div class="flex justify-between flex-col">
            <div class="logo">
              <img src="@/assets/images/logo.png">
              <span>赚钱呗</span>
            </div>
            <div>Copyright©2019-2024 168zqb.com  All Rights Reserved</div>
            <div>蜀ICP备2022002573号</div>
          </div>
          <div class="contact">
            <div class="title">客服电话</div>
            <div>0571-56076688</div>
            <div class="title" style="margin-top: 12px;">商务合作</div>
            <div>QQ：2691133658</div>
            <div>微信：yaya19003</div>
          </div>
        </div>
        <div class="download">
          <div>下载商户版APP</div>
          <img class="qrcode" src="@/assets/images/qrcode-android.png">
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { appInfoApi } from '@/api/app.js'
import { logoutApi } from '@/api/user.js'
import { connectIM } from '@/utils/jim/im.js'
import EventType from "@/wfc/client/wfcEvent"
import { mapState } from 'vuex'
export default {
  name: 'Layout',
  data() {
    return {
      connectStatus: 0,
    }
  },
  computed: {
    ...mapState('im', ['unread']),
    ...mapState(['userInfo','isRealName'])
  },
  created() {
    this.getUserInfo()
    this.connectIm()
  },
  methods: {
    getUserInfo() {
      this.$store.dispatch('GET_USER_INFO').then(res => {
        this.getAppInfo()
      })
    },
    getAppInfo() {
      appInfoApi().then(res => {
        console.log('应用信息', res)
        const vipLevel = this.$store.state.userInfo.vipLevel
        this.$store.commit('SET_MEMBER_BENEFIT', res.data.memberBenefits[vipLevel])
      }).catch(err => {
        this.$tips({ type: 'error', message: err })
      })
    },
    logout() {
      this.$confirm('是否确定退出系统？').then(action => {
        if(action == 'confirm') {
          logoutApi({
            scene: 'logout',
            userId: localStorage.getItem('USER_ID')
          }).then(res => {
            console.log('退出登录', res)
            this.$store.commit('LOGOUT')
          }).catch(err => {
            this.$tips({ type: 'error', message: err })
          })
        }
      }).catch(err => {})
    },
    connectIm() {
      connectIM()
      // this.$im.eventEmitter.on(EventType.ReceiveMessage, this.setUnread)
      // this.$im.eventEmitter.on(EventType.ConnectionStatusChanged, status => {
      //   console.log('连接状态', status)
      //   if (status == 1) {
      //     this.$im.getConversationList()
      //     const timer = setTimeout(() => {
      //       this.setUnread()
      //       clearTimeout(timer)
      //     }, 1000)
      //   }
      // })
      this.$im.eventEmitter.on(EventType.ReceiveMessage, this.setUnread)
      this.$im.eventEmitter.on('conversationInfoLoaded', ()=>{
        this.setUnread(true)
      })
      this.$im.eventEmitter.on(EventType.ConnectionStatusChanged, status => {
        console.log('连接状态', status)
        if(status == 1 && this.connectStatus != 1) {
          this.setUnread()
        }
        this.connectStatus = status
      })
    },
    setUnread(local = false) {
      this.$store.dispatch('im/UPDATE_UNREAD',{local})
    }
  }
}
</script>

<style lang="scss" scoped>
$mainWidth: 1200px;
.layout {
  width: 100%;
  min-width: 1200px;
  min-height: 100vh;
  background: var(--theme-page-bg);
}
header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  background: #fff;
  min-width: $mainWidth;
  .header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: $mainWidth;
    width: $mainWidth;
    .left {
      display: flex;
      align-items: center;
      .logo {
        display: flex;
        align-items: center;
        img {
          width: 52px;
          height: 52px;
          margin-right: 12px;
          border-radius: 8px;
        }
        span {
          font-size: 30px;
          font-weight: bold;
          color: #2A2A2A;
        }
      }
    }
    .nav {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      color: #65666F;
      margin-left: 50px;
      &>div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 66px;
        height: 80px;
        margin-right: 50px;
        cursor: pointer;
        &:hover {
          color: var(--theme-color);
        }
      }
      .active {
        position: relative;
        color: var(--theme-color);
        border-bottom: 2px solid var(--theme-color);
        // &::after {
        //   content: '';
        //   position: absolute;
        //   bottom: -28px;
        //   left: 50%;
        //   transform: translateX(-50%);
        //   width: 66px;
        //   height: 2px;
        //   background: var(--theme-color);
        // }
      }
      .nav-message {
        position: relative;
        .unread {
          position: absolute;
          top: -10px;
          right: 0;
          transform: translateX(100%);
          height: 18px;
          line-height: 18px;
          padding: 0 4px;
          border-radius: 9px 9px 9px 0;
          color: #fff;
          background-color: #FF5F58;
          font-size: 12px;
        }
      }
    }
    .btn-user {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #2A2A2A;
      cursor: pointer;
      .avatar {
        width: 44px;
        height: 44px;
        margin-right: 8px;
        border-radius: 22px;
      }
      .arrow-down {
        width: 18px;
        height: 18px;
        margin-left: 8px;
      }
    }
  }
}

.user-dropdown {
  display: flex;
  align-items: center;
  height: 46px;
  line-height: 46px;
  color: #65666F;
  font-size: 14px;
  cursor: pointer;
  img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
}

main {
  display: flex;
  justify-content: center;
  padding: 24px 0 48px;
  min-height: 820px;
  min-width: $mainWidth;
  .content {
    min-width: $mainWidth;
    width: $mainWidth;
  }
}

footer {
  display: flex;
  justify-content: center;
  background: #343B3B;
  color: #fff;
  padding: 24px 0;
  min-width: $mainWidth;
  .footer-row {
    min-width: $mainWidth;
    width: $mainWidth;
    display: flex;
    justify-content: space-between;
  }
  .left {
    display: flex;
    justify-content: space-between;
    width: 894px;
    .logo {
      display: flex;
      align-items: center;
      font-size: 26px;
      font-weight: bold;
      img {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        margin-right: 12px;
      }
    }
    .contact {
      font-size: 16px;
      color: #fff;
      .title {
        font-size: 14px;
        color: #BABFBF;
        margin-bottom: 2px;
      }
    }
  }
  .download {
    font-size: 14px;
    color: #BABFBF;
    .qrcode {
      width: 96px;
      height: 96px;
      margin-top: 12px;
    }
  }
}


</style>
