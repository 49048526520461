// const baseURL = 'http://192.168.0.104:8080/' // 测试环境
const baseURL = 'http://118.178.143.133/' // 测试环境 https://apidev.xuniuwang.com/
// const baseURL = 'https://openapi.zhihuixiaoqun.com/'  // 正式环境
// const baseURL = '/api/'

const productId = 'TASK_UNION'
const channel = 'pc_mer'
const versionName = '1.0.0'

module.exports = {
  // HTTP_REQUEST_URL: baseURL,
  HTTP_REQUEST_URL: process.env.VUE_APP_API_URL,
  PRODUCT_ID: productId,
  CHANNEL: channel,
	VERSION_NAME: versionName,
  PWD_KEY: 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAh4C5CzmAtVQIcDaI/UD8uOlkvvX8g+IuQCA1ax+h9fIa9bG148Tq1sXCOujkH9ER+1Oa6hFd2LM0fUeCjUQ3CSR61IGkpA226M2OnqXl8Koa1e6lZkBaSKOLu7sem9aKhnMJpaD389fQ349FLELH42keBpD0wp30RT6QSxjmqkLLbGzgbrCLIs/MCRAxvFiGTUoGk3pHPM6CWDLyvlDwBTFHMSYpLNP3OLBH9sfroX4m0eyAiSSzkneqyozEl8YQyG0T404YfHGUQSaqkwY6lkOFuS83KgM/flFwqkSPDUhoVrtpaqib9GcQsE2rzPA7kK4eFwn1upxzoynNtbHTYQIDAQAB',
}
