import JimClient from 'jim-client'
export default {
	namespaced: true,
	state: () => ({
		unread: 0
	}),
	mutations: {
		SET_UNREAD(state, num) {
			if(!num) {
				state.unread = JimClient.getUnreadCount([0, 1, 2, 3], [0]).unread
			} else {
				state.unread = num
			}
		}
	},
	actions: {
		UPDATE_UNREAD({commit}, {num,local = false}) {
			if(num || num === 0) {
				commit('SET_UNREAD', num)
			} else {
				JimClient.getConversationList([0, 1, 2],[0],local).then(list => {
					console.log('-------UPDATE_UNREAD list--------', list)
					let count = 0
					if(list && list.length > 0){
						list.forEach((item, index) => {
							if (!item.isSilent && item.unreadCount.unread) {
								count += item.unreadCount.unread
							}
						})
						console.log('未读消息数', count)
						commit('SET_UNREAD', count)
					}
				})
			}
		}
	}
}
